import React, { useEffect, useState  } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import Slots from './slots';
import Ltgame from './lottery';
import Sports from './sports';
import Casino from './casino';
import Pvc from './pvc';
import Fishing from './fishing';
import MiniGame from './mini';
// import '../assets/css/home/page-home.css';
import '../assets/css/home/maruf.css';


const Home = () => {
   const [activeGame, setActiveGame] = useState('Win Go1Min');
   const [maxWidth, setMaxWidth] = useState(window.innerWidth);
   const navigate = useNavigate();

  const handleGameClick = (gameName) => {
    setActiveGame(gameName);
  };

 

  const handleDivClickAccount = () => {
   // Navigate to the desired route
   navigate('/account'); // Replace with your desired route
 };
  useEffect(() => {
    function updateFontSize() {
      let vw = window.innerWidth;
      let fontSize = vw / 10; // Calculate font size as 1/14th of viewport width
      document.documentElement.style.fontSize = fontSize + 'px';

    }

    // Call updateFontSize after the component is mounted
    updateFontSize();

    // Add event listeners to update font size on window resize
    window.addEventListener('resize', updateFontSize);

    // Remove event listener on component unmount to prevent memory leaks
    return () => {
      window.removeEventListener('resize', updateFontSize);
    };
  }, []); // Empty dependency array ensures this effect runs only once after mount

  useEffect(() => {
   const handleResize = () => {
     const screenWidth = window.innerWidth;

     if (screenWidth < 500) {
       // Set maxWidth to the current screen width if it's under 500px
       setMaxWidth(screenWidth);
     } else {
       // Reset to default (500px) if the screen width is 500px or more
       setMaxWidth(500);
     }
   };

   // Add event listener for window resize
   window.addEventListener('resize', handleResize);

   // Run the effect once initially to set the initial max width
   handleResize();

   // Cleanup function to remove the event listener when the component is unmounted
   return () => {
     window.removeEventListener('resize', handleResize);
   };
 }, []); // Empty dependency array ensures effect is only set up once on mount

 

   const [currentActive, setCurrentActive] = useState('lottery');

   // Handler function to set the active menu item
   const handleMenuClick = (menuItem) => {
     setCurrentActive(menuItem);
   };

  return (

    <>




      

      <link rel="stylesheet" href="../assets/css/home/modules.css"/>
      <link rel="stylesheet" href="../assets/css/home/page-activity.css"/>
      <link rel="stylesheet" href="../assets/css/home/index.css"/>

   
      <div id="app" >
         <div  className="ar-loading-view" style={{ display: 'none' }}>
            <div  className="loading-wrapper">
               
               <div  className="com__box" style={{display: 'none'}}>
                  <div className="loading" >
                     <div className="shape shape-1" ></div>
                     <div className="shape shape-2" ></div>
                     <div className="shape shape-3" ></div>
                     <div className="shape shape-4" ></div>
                  </div>
               </div>
            </div>
            <div  className="skeleton-wrapper" style={{ display: 'none' }}>
               <div  className="van-skeleton van-skeleton--animate">
                 
                  <div className="van-skeleton__content">
                    
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{width: '60%'}}></div>
                  </div>
               </div>
               <div  className="van-skeleton van-skeleton--animate">
                  <div className="van-skeleton-avatar van-skeleton-avatar--round"></div>
                  <div className="van-skeleton__content">
                     <h3 className="van-skeleton-title"></h3>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{width: "60%;"}}></div>
                  </div>
               </div>
               <div  className="van-skeleton van-skeleton--animate">
                 
                  <div className="van-skeleton__content">
                     <h3 className="van-skeleton-title"></h3>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{ width: '100%' }}></div>
                     <div className="van-skeleton-paragraph" style={{width: "60%;"}}></div>
                  </div>
               </div>
            </div>
         </div>
        
         <div  id="home" className="content" style={{ maxWidth: `${maxWidth}px` }} >
            <div  className="navbar">
            <div
  
  className="navbar-fixed"
  style={{
    background: 'linear-gradient(90deg, rgb(255, 152, 1) 0%, rgb(230, 116, 1) 100%)',
  }}
>                  <div  className="navbar__content">
                     <div  className="navbar__content-left"><img  src="https://vglottery.com/assets/logo-white.png" alt=""/></div>
                     <div  className="navbar__content-center">
                       
                        <div  className="navbar__content-title"></div>
                     </div>
                     <div  className="navbar__content-right">
                        <div  className="content_right">
                           <div  className="message">
                              <img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHHSURBVHgB7Zi/TsMwEMavwA4S7FgqO0PZO5Sdgb0LOwMPgFQeAInuIMEbsMMAO0P3DuEJ6AMghTvFDo7lP3GBnkP9kz41ia7JfXFsn90ry3ITOswGdJxsgJt/a2AHdYmaoz6Z9YY6Rwlboj3LKESBT64/MFKgjuVvjWlAQJrJKwrUEWqhLpifEH02AtJFQPU51ZgtMIe0DRD09vfUid4C1HEFpE8jT9NA58gTGTfZADdrZ4DG4AvUQP76mKFGMnYWiL1CHaDOQJtlW0ETmVS/DDPR4kk3nti+FreL+nDE3Rv3nJRh6nvHtsC7ce56WwU0i65FINb3DC+5E3OzdgaGxvmJI04YsQLcheIwcO5lC+IYo7ahGhbpQYee2FvUK1Sdd+yJo/vQsvFRHkcZ0NcDAqr1QBegOaOgg9yJuckGuMkGuNENxJWxvFg3tuhiAelDk6hzZ24K6dPI0ba5+wyR0/kKobc/0C/YOvEp6gHSg2qlkXnR1gIKagWqNs2CbR/abUHSdxpaC7e5B63QKPkXW4DPgIs78FeX9NCp1J+PbLHltI+VJq74DQMsiSt+YoA1ccUyfeAavpNnn72XMZAUuRrlJhvg5gsXMzB4jB69zgAAAABJRU5ErkJggg=="/>
                              <div   className="point point-flicker point"></div>
                           </div>
                           <img  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE9SURBVHgB7ZcBroIwDIbLC/d4u4nvJo+bOE+iR9tNcDXFoOk2hdZC7JcsVFfG/5NuYwCO46gxjuOADfYIiZ8YQIkf0CMUYlE0DXwEN2CNG7DGDVjjBqxxA9a4AWu+14DUYWXtOH1pULx2XXep9J8p/s15J1hAvveYL5Hi1vPY/r4hrjjojEh5b5mYi2/kDTU9XAmFQnyHBpkLjiToJRjxp8qLCoX4xuI5kB8YYYGJgvgIC1m1Cr1rQlo80sNKUADWZmYSPv1+QEM8stoAwpnILc1S/uGxfkXEI2IbGVNOoRCLiUdEd2LGxDOi4hHxT4mKCXHxiMq3EGNCRTwiMok5aGInii+ghJoBRFP4hJ8HrHED1uzeQGsVOuSlMIIth1pny8Aftc3ClVCC7ZKe/+i4LDqHBtgW6RMbo+PsjStFnZ1IVwV7+gAAAABJRU5ErkJggg==" className="down"/>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div  className="swiper swiper-initialized swiper-horizontal swiper-pointer-events my-swipe swiper-backface-hidden">
               <div className="swiper-wrapper" style={{transform: "translate3d(-1680px, 0px, 0px); transition-duration: 300ms;"}}>
                  <div className="swiper-slide swiper-slide-duplicate" data-swiper-slide-index="6" style={{width: '400px', marginRight: "20px"}}><img className="" data-origin="https://vglottery.com/assets/banner1.jpg" src="https://vglottery.com/assets/banner1.jpg"/></div>
                  <div className="swiper-slide" data-swiper-slide-index="0"style={{width: '400px', marginRight: "20px"}}><img className="" data-origin="https://vglottery.com/assets/banner1.jpg" src="https://vglottery.com/assets/banner1.jpg"/></div>
               </div>
            
            </div>
            <div  className="noticeBar__container">
               <div className="noticeBar__container-body">
                  <div className="noticeBar__container-body-text">VG Lottery</div>
               </div>
               <button>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g clip-path="url(#clip0_589_37755)">
                        <path d="M24 0H0V24H24V0Z" fill="white" fill-opacity="0.01"></path>
                        <path d="M12 22C16.1173 22 19.4999 18.7371 19.4999 14.5491C19.4999 13.5209 19.4476 12.4187 18.8778 10.7058C18.3079 8.9929 18.1931 8.7718 17.5905 7.71395C17.333 9.8727 15.9555 10.7724 15.6055 11.0413C15.6055 10.7615 14.7722 7.66795 13.5088 5.81695C12.2685 4 10.5817 2.80796 9.59265 2C9.59265 3.53489 9.16095 5.81695 8.5427 6.9797C7.92445 8.14245 7.80835 8.1848 7.0361 9.0501C6.2639 9.9154 5.90945 10.1826 5.2637 11.2325C4.61798 12.2825 4.5 13.6809 4.5 14.7091C4.5 18.8971 7.88265 22 12 22Z" fill="white"></path>
                     </g>
                     <defs>
                        <clipPath id="clip0_589_37755">
                           <rect width="24" height="24" fill="white"></rect>
                        </clipPath>
                     </defs>
                  </svg>
                  Detail
               </button>
            </div>
            <div   className="home_menu">
               <div   className={`menu_item ${currentActive === 'lottery' ? 'active' : ''}`}
          onClick={() => handleMenuClick('lottery')}><img  className="lottery" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033613klhe.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033613klhe.png"/><span >Lottery</span></div>
               <div className={`menu_item ${currentActive === 'slots' ? 'active' : ''}`}
          onClick={() => handleMenuClick('slots')}><img  className="slot" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_202312150336204mtb.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_202312150336204mtb.png"/><span >Slots</span></div>
               <div  className={`menu_item ${currentActive === 'sports' ? 'active' : ''}`}
          onClick={() => handleMenuClick('sports')}><img  className="sport" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033607yi17.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033607yi17.png"/><span >Sports</span></div>
               <div  className={`menu_item ${currentActive === 'Casino' ? 'active' : ''}`}
          onClick={() => handleMenuClick('Casino')}><img  className="video" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033600k8os.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033600k8os.png"/><span >Casino</span></div>
              
               <div  className={`menu_item ${currentActive === 'Pvc' ? 'active' : ''}`}
          onClick={() => handleMenuClick('Pvc')}><img  className="chess" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033554mpgb.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033554mpgb.png"/><span >PVC</span></div>
              
              
               <div  className={`menu_item ${currentActive === 'Fishing' ? 'active' : ''}`}
          onClick={() => handleMenuClick('Fishing')}><img  className="fish" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033528g3gt.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_20231215033528g3gt.png"/><span >Fishing</span></div>
              
              
               <div  className={`menu_item ${currentActive === 'MiniGame' ? 'active' : ''}`}
          onClick={() => handleMenuClick('MiniGame')}><img  className="flash" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_2023121503353389nc.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_2023121503353389nc.png"/><span >Mini games</span></div>
              
              
               <div  className="menu_item"><img  className="popular" alt="" data-origin="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_202312150336366phx.png" src="https://ossimg.9987cw.cc/TC/gamecategory/gamecategory_202312150336366phx.png"/><span >Popular</span></div>
            </div>
            {currentActive === 'lottery' && <Ltgame />}
            {currentActive === 'slots' && <Slots />}
            {currentActive === 'sports' && <Sports/>}
            {currentActive === 'Casino' &&<Casino/>}
            {currentActive === 'Pvc' &&<Pvc/>}
            {currentActive === 'Fishing' &&<Fishing/>}
            {currentActive === 'MiniGame' &&<MiniGame/>}



            <div   className="home_container">
               <div  className="title"   style={{
    backgroundImage: 'url("https://9987up.co/assets/png/lottery-7b8f3f55.png")', // Proper camelCase and quoting
  }}></div>
         
            </div>
         
         
       
         </div>           

         <div  className="tabbar__container" >

            <div  className="tabbar__container-item active">

               <div  className="home">Home</div>
             
            </div>

           
            <div  className="tabbar__container-item">
               <div  className="activity">Activity</div>
            </div>
            <div  className="tabbar__container-item">
               <div  className="promotion">Promotion</div>
            </div>
            <div  className="tabbar__container-item">
               <div  className="wallet">Wallet</div>
            </div>
            <div onClick={handleDivClickAccount}  className="tabbar__container-item">
                         <div  className="main"> Account              
</div> 
            </div>
         </div>
      </div>

</>

  );
};

export default Home;
