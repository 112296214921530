import React, { useEffect, useState } from 'react';
import axios from 'axios';
import fetchGameInfo from './wingotime';
import eventEmitter from '../events'; // Import the shared EventEmitter

import { useLocation } from 'react-router-dom';
import { EventEmitter } from 'events';
const emitter = new EventEmitter();



const sendBettingRequest = async (bettingData) => {
    try {
        const token = localStorage.getItem('user_access_token');
        const userId = localStorage.getItem('userId');
        if (!token) {
          return;
        }

        const response = await axios.post(
          'https://vglottery.com/web_api/GameBetting',bettingData,
          {headers: {Authorization: `Bearer ${token}`}}
        );

        
        if (response.data.msg === 'Bet success') {
          eventEmitter.emit('walletReload'); // Emit the event
          return response.data;
      }
  } catch (error) {
      console.error('Error sending betting request:', error);
      return null;
  }
};


const PopupContent = ({ activeGame, type, onClose, game }) => {
    let content;
    let modelName;
    let modelNumber;

    let className;    const [currentBalance, setCurrentBalance] = useState(1);
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [currentMultiplier, setCurrentMultiplier] = useState(1);
    const [totalAmount, setTotalAmount] = useState(1);
    const [gameData, setGameData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(''); // Success or error message
    const [userId, setUserId] = useState('');
    const location = useLocation(); // Get the current URL location
    const searchParams = new URLSearchParams(location.search); // Create a URLSearchParams instance
    const typeId = searchParams.get('wingo'); // Extract the 'wingo' parameter
    const [issueNumber, setIssueNumber] = useState(null);
  
  
    
  useEffect(() => {
    // Get the userId from cookies when the component mounts
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId); // Set the state with the retrieved userId
    }
  }, []); // The empty dependency array ensures this effect runs only once after the component is mounted

    const updateTotal = () => {
        const total = currentBalance * currentQuantity * currentMultiplier;
        setTotalAmount(total.toFixed(2));
    };

    const handleBalanceClick = (e) => {
        const balanceItems = document.querySelectorAll('.Betting__Popup-body-line-list')[0].children;
        Array.from(balanceItems).forEach((item) => item.classList.remove('bgcolor'));

        const newBalance = parseInt(e.target.textContent, 10);
        setCurrentBalance(newBalance);
        e.target.classList.add('bgcolor');
        updateTotal();
    };

    const handleMultiplierClick = (e) => {
        const factorItems = document.querySelectorAll('.Betting__Popup-body-line-list')[1].children;
        Array.from(factorItems).forEach((item) => item.classList.remove('bgcolor'));

        const newMultiplier = parseInt(e.target.textContent.replace('X', ''), 10);
        setCurrentMultiplier(newMultiplier);
        e.target.classList.add('bgcolor');
        updateTotal();
    };

    const handleIncrease = () => {
        setCurrentQuantity((prev) => prev + 1);
        updateTotal();
    };

    const handleDecrease = () => {
        if (currentQuantity > 1) {
            setCurrentQuantity((prev) => prev - 1);
        } else {
           
        }
        updateTotal();
    };

    const handleCancel = () => {
        const popupGreen = document.getElementById('popupgreen');
        const blackscreen = document.getElementById('blackscreen');
        if (popupGreen && blackscreen) {
            popupGreen.style.display = 'none';
            blackscreen.style.display = 'none';
        }
    };

    useEffect(() => {
        const increaseBtn = document.getElementById('increase');
        const decreaseBtn = document.getElementById('decrease');
        const cancelButton = document.getElementById('cancelButton');

        if (increaseBtn) {
            increaseBtn.addEventListener('click', handleIncrease);
        }

        if (decreaseBtn) {
            decreaseBtn.addEventListener('click', handleDecrease);
        }

        if (cancelButton) {
            cancelButton.addEventListener('click', onClose);
        }

        const balanceItems = document.querySelectorAll('.Betting__Popup-body-line-list')[0].children;
        const factorItems = document.querySelectorAll('.Betting__Popup-body-line-list')[1].children;

        Array.from(balanceItems).forEach((item) => item.addEventListener('click', handleBalanceClick));
        Array.from(factorItems).forEach((item) => item.addEventListener('click', handleMultiplierClick));

        return () => {
            if (increaseBtn) {
                increaseBtn.removeEventListener('click', handleIncrease);
            }

            if (decreaseBtn) {
                decreaseBtn.removeEventListener('click', handleDecrease);
            }

            if (cancelButton) {
                cancelButton.removeEventListener('click', onClose);
            }

            Array.from(balanceItems).forEach((item) => item.removeEventListener('click', handleBalanceClick));
        };
    }, []);

   
    


    useEffect(() => {
        updateTotal(); // Recalculate when dependencies change
    }, [currentBalance, currentQuantity, currentMultiplier]);


    switch (type) {
        // Cases for color types and larger/smaller bets
        case 'Green':
          content = "Green"; // Content for 'Green' type
          className = "Betting__Popup-11"; // Class name for 'Green' type
          modelName = "button"; // Common type for color categories
          modelNumber = "11"; 
          break;
      
        case 'Red':
          content = "Red"; 
          className = "Betting__Popup-10"; 
          modelName = "button"; 
          modelNumber = "10"; 
          break;
      
        case 'Violet':
          content = "Violet"; 
          className = "Betting__Popup-12"; 
          modelName = "button"; 
          modelNumber = "12"; 
          break;
      
        case 'Big':
          content = "Big"; 
          className = "Betting__Popup-13"; 
          modelName = "button"; 
          modelNumber = "13"; 
          break;
      
        case 'Small':
          content = "Small"; 
          className = "Betting__Popup-14"; 
          modelName = "button"; 
          modelNumber = "14"; 
          break;
      
        // Cases for numbers, not under color or size categories
        case 'Number 0':
          content = "0"; 
          className = "Betting__Popup-0"; 
          modelName = "number"; // Category for numerical types
          modelNumber = "0"; 
          break;
      
        case 'Number 1':
          content = "1"; 
          className = "Betting__Popup-1"; 
          modelName = "number"; 
          modelNumber = "1"; 
          break;
      
        case 'Number 2':
          content = "2"; 
          className = "Betting__Popup-2"; 
          modelName = "number"; 
          modelNumber = "2"; 
          break;
      
        case 'Number 3':
          content = "3"; 
          className = "Betting__Popup-3"; 
          modelName = "number"; 
          modelNumber = "3"; 
          break;
      
        // Continuing with number cases
        case 'Number 4':
          content = "4"; 
          className = "Betting__Popup-4"; 
          modelName = "number"; 
          modelNumber = "4"; 
          break;
      
        case 'Number 5':
          content = "5"; 
          className = "Betting__Popup-5"; 
          modelName = "number"; 
          modelNumber = "5"; 
          break;
      
        case 'Number 6':
          content = "6"; 
          className = "Betting__Popup-6"; 
          modelName = "number"; 
          modelNumber = "6"; 
          break;
      
        case 'Number 7':
          content = "7"; 
          className = "Betting__Popup-7"; 
          modelName = "number"; 
          modelNumber = "7"; 
          break;
      
        case 'Number 8':
          content = "8"; 
          className = "Betting__Popup-8"; 
          modelName = "number"; 
          modelNumber = "8"; 
          break;
      
        case 'Number 9':
          content = "9"; 
          className = "Betting__Popup-9"; 
          modelName = "number"; 
          modelNumber = "9"; 
          break;}


      useEffect(() => {
        const fetchGameIssue = async () => {
          try {
            const response = await axios.post('https://vglottery.com/web_api/GetGameIssue', { typeId });
    
            if (response.data.code === 0) {
              const { endTime, serviceTime, issueNumber: fetchedIssueNumber } = response.data.data;
              setIssueNumber(fetchedIssueNumber);
    
              const start = new Date(serviceTime);
              const end = new Date(endTime);
    
              if (isNaN(start) || isNaN(end)) {
                console.error('Invalid time data.');
                return;
              }
            } else {
              console.error('API error:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error.message);
          }
        };
        if (typeId) { // Ensure typeId is available
          fetchGameIssue();
        }
      }, [typeId]); // Dependency on typeId to refetch if it changes


      const handlePlaceBet = async () => {
        if (totalAmount < 20) {
          alert('Total amount must be at least ₹20.'); // Alert when totalAmount is less than 20
          return; // Exit the function early if the total amount is too low
        }
        setIsLoading(true);

        const bettingData = {
          user_id: userId, // Example user ID
          type: modelName, // Derived from component state
          value: modelNumber, // Derived from user interaction
          timeleft: 60, // Example time left for the bet
          issuenumber: issueNumber, // Example game ID
          amount: totalAmount, // Total bet amount
          typeId: typeId, // Example game type ID
        };
        const result = await sendBettingRequest(bettingData); // Send the betting request to the API
      
        if (result) {
        
          alert(`Bet placed successfully for ₹${totalAmount}.`); // Display the success message

setIsLoading(false);

// If there's a reference to cancelButton, clean up its click event
const cancelButton = document.getElementById('cancelButton');

if (cancelButton) {
  // Remove the event listener before reassigning or disposing of it
  cancelButton.removeEventListener('click', onClose);
}

// Now execute the logic you'd want without clicking the button
if (typeof onClose === 'function') {
  onClose(); // Call the onClose function directly
} else {
  console.warn("onClose function is not defined.");
}

// You can add additional logic here if needed
     
          
        } else {
          console.error('Failed to place bet');
          alert('Sorry, the bet could not be placed. Please try again.'); // Error message
        }
      };
     
      
      const typeMapping = {
        1: "1 Min",
        2: "3 Min",
        3: "5 Min",
        4: "10 Min",
      };
    
      const wingoType = typeMapping[typeId] || "Unknown"; // Get the correct type
    

  return (

    <>
    <div id="blackscreen" class="van-overlay" data-v-03b970bb="" style={{zIndex:"2000"}} ></div>







    <div style={{zIndex:"2001"}} id="popupgreen" role="dialog" tabindex="0" class="van-popup van-popup--round van-popup--bottom" data-v-03b970bb="" >
       <div data-v-03b970bb="" className={className}


       
       
       >

        
          <div data-v-03b970bb="" class="Betting__Popup-head">
             <div data-v-03b970bb="" class="Betting__Popup-head-title">Win Go {wingoType}</div>
             <div data-v-03b970bb="" class="Betting__Popup-head-selectName"><span data-v-03b970bb="">Select</span><span data-v-03b970bb="">{content}</span></div>
          </div>
          <div data-v-03b970bb="" class="Betting__Popup-body">
             <div data-v-03b970bb="" class="Betting__Popup-body-line">
                Balance 
                <div data-v-03b970bb="" class="Betting__Popup-body-line-list">
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item r bgcolor">1</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item r">10</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item r">100</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item r">1000</div>
                </div>
             </div>
             <div data-v-03b970bb="" class="Betting__Popup-body-line">
                Quantity 
                <div data-v-03b970bb="" class="Betting__Popup-body-line-btnL">
                   <div id="decrease" data-v-03b970bb="" class="Betting__Popup-btn bgcolor">-</div>
                   <div data-v-03b970bb="" class="van-cell van-field Betting__Popup-input" modelmodifiers="[object Object]">
                      
                      <div class="van-cell__value van-field__value">
                         <div class="van-field__body">
  {currentQuantity}
                         </div>
                         
                      </div>
                      
                   </div>
                   <div  id="increase" data-v-03b970bb="" class="Betting__Popup-btn bgcolor">+</div>
                </div>
             </div>
             <div data-v-03b970bb="" class="Betting__Popup-body-line">
                <div data-v-03b970bb=""></div>
                <div data-v-03b970bb="" class="Betting__Popup-body-line-list">
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item bgcolor"> X1</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item"> X5</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item"> X10</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item"> X20</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item"> X50</div>
                   <div data-v-03b970bb="" class="Betting__Popup-body-line-item"> X100</div>
                </div>
             </div>
             <div data-v-03b970bb=""  class="Betting__Popup-body-line"><span data-v-03b970bb="" class="Betting__Popup-agree">I agree</span><span data-v-03b970bb="" class="Betting__Popup-preSaleShow">《Pre-sale rules》</span></div>
          </div>
          <div data-v-03b970bb="" class="Betting__Popup-foot">
             <div data-v-03b970bb="" id="cancelButton" class="Betting__Popup-foot-c">Cancel</div>
            

             <div
  data-v-03b970bb=""
  onClick={handlePlaceBet}
  className={`Betting__Popup-foot-s bgcolor`} // Use className instead of class for JSX
>
  {isLoading ? 'Placing...' : `Total amount ₹${totalAmount}`} 
</div>
          
          </div>
       </div>
       
       
    </div> 
    </>
  );


};
  
export default PopupContent;
