import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import Betting from './betting';
import GameItem from './wingotime';
import Wallet from './wallet.js';
const WinGo = () => {
   const [activeGame, setActiveGame] = useState('Win Go1Min');
   const [maxWidth, setMaxWidth] = useState(window.innerWidth);
   const [token, setToken] = useState(localStorage.getItem('user_access_token') || null);
 
   const navigate = useNavigate();
   useEffect(() => {
   if (!token) {
      navigate('/login');
      return;
   }
   }, [token, navigate]);

  const handleGameClick = (gameName) => {
    setActiveGame(gameName);
  };


  useEffect(() => {
   // Function to update the root font size based on viewport width
   function updateFontSize() {
     const vw = window.innerWidth; // Get viewport width
     let fontSize;

     // If the viewport width is less than 500px, adjust the font size
     if (vw < 500) {
       fontSize = vw / 10; // Calculate font size as a fraction of viewport width
     } else {
       fontSize = 50; // Default font size for larger screens
     }

     // Set the font size for the root element
     document.documentElement.style.fontSize = fontSize + 'px';
   }

   // Update font size when the component is mounted
   updateFontSize();

   // Add event listeners to update font size on window resize
   window.addEventListener('resize', updateFontSize);

   // Cleanup: remove the event listener on component unmount to prevent memory leaks
   return () => {
     window.removeEventListener('resize', updateFontSize);
   };
 }, []); // Empty dependency array to run the effect only once after mount

  useEffect(() => {
   const handleResize = () => {
     const screenWidth = window.innerWidth;

     if (screenWidth < 540) {
       // Set maxWidth to the current screen width if it's under 500px
       setMaxWidth(screenWidth);
     } else {
       // Reset to default (500px) if the screen width is 500px or more
       setMaxWidth(540);
     }
   };

   // Add event listener for window resize
   window.addEventListener('resize', handleResize);

   // Run the effect once initially to set the initial max width
   handleResize();

   // Cleanup function to remove the event listener when the component is unmounted
   return () => {
     window.removeEventListener('resize', handleResize);
   };
 }, []); // Empty dependency array ensures effect is only set up once on mount

 
  React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", style: { position: "absolute", width: 0, height: 0 } })

  return(


    <>
    
 


    <div id="app" style={{ maxWidth: `${maxWidth}px` }}  data-v-app="">
 
       
         <div data-v-b67ac651=""  class="WinGo__C maxwidth" >
    
            <div style={{ maxWidth: `${maxWidth}px` }} data-v-3949aac6="" data-v-b67ac651="" class="navbar">
               <div data-v-3949aac6="" class="navbar-fixed">
                  <div data-v-3949aac6="" class="navbar__content">
                     <div data-v-3949aac6="" class="navbar__content-left">
                     <Link to="/" className="backIcon" data-v-3949aac6="">
                        <div data-v-3949aac6="" class="backIcon">
                       
                        </div>
                       </Link>
                     </div>
                     <div data-v-3949aac6="" class="navbar__content-center">
                        <div data-v-3949aac6="" class="headLogo" ></div>
                        <div data-v-3949aac6="" class="navbar__content-title"></div>
                     </div>
                     <div data-v-3949aac6="" class="navbar__content-right">
                        <div data-v-b67ac651="" class="WinGo__C-head-more">
                           <div data-v-b67ac651=""></div>
                           <div data-v-b67ac651="" class=""></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         


<Wallet/>




            <div data-v-b67ac651="" class="noticeBar__container">
               <div class="noticeBar__container-body">
                  <div class="noticeBar__container-body-text">Coming soon</div>
               </div>
               <button>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g clip-path="url(#clip0_589_37755)">
                        <path d="M24 0H0V24H24V0Z" fill="white" fill-opacity="0.01"></path>
                        <path d="M12 22C16.1173 22 19.4999 18.7371 19.4999 14.5491C19.4999 13.5209 19.4476 12.4187 18.8778 10.7058C18.3079 8.9929 18.1931 8.7718 17.5905 7.71395C17.333 9.8727 15.9555 10.7724 15.6055 11.0413C15.6055 10.7615 14.7722 7.66795 13.5088 5.81695C12.2685 4 10.5817 2.80796 9.59265 2C9.59265 3.53489 9.16095 5.81695 8.5427 6.9797C7.92445 8.14245 7.80835 8.1848 7.0361 9.0501C6.2639 9.9154 5.90945 10.1826 5.2637 11.2325C4.61798 12.2825 4.5 13.6809 4.5 14.7091C4.5 18.8971 7.88265 22 12 22Z" fill="white"></path>
                     </g>
                     <defs>
                        <clipPath id="clip0_589_37755">
                           <rect width="24" height="24" fill="white"></rect>
                        </clipPath>
                     </defs>
                  </svg>
                  Detail
               </button>
            </div>
            <GameItem activeGame={activeGame} onGameClick={handleGameClick} />

       
          


            <div data-v-9f348ae7="" data-v-b67ac651="" class="dialog inactive">
               <div data-v-9f348ae7="" class="dialog__container" role="dialog" tabindex="0">
                  <div data-v-9f348ae7="" class="dialog__container-img"></div>
                  <div data-v-9f348ae7="" class="dialog__container-title">
                     <h1 data-v-9f348ae7=""></h1>
                  </div>
                  <div data-v-9f348ae7="" class="dialog__container-content">
                     <div data-v-b67ac651=""></div>
                  </div>
                
               </div>
               <div data-v-9f348ae7="" class="dialog__outside"></div>
            </div>
           
         </div>
      </div>
   
    













    </>


  );
};

export default WinGo;
