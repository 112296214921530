import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use this for React Router v6 and later

import axios from 'axios';

const History = ({countdown}) => {
  const [activeGame, setActiveGame] = useState('Win Go1Min');
  const [issueNumber, setIssueNumber] = useState('');
  const [list, setIslist] = useState('');
  const [gameData, setGameData] = useState([]);
  const [key, setKey] = useState(0);
  const navigate = useNavigate(); // Call `useNavigate` within the component


 

  const fetchGameInfo = async () => {
    try {
      const response = await axios.post(
        'https://vglottery.com/web_api/GetNoaverageEmerdList',
        {
          "pageSize": 10,
          "pageNo": 1,
          "typeId": 1
        }
      );
      if (response && response.data && response.data.data && response.data.data.list) {
        setGameData(response.data.data.list);
      } else {
        console.error('Unexpected response code:', response.data.list);
      }
    } catch (error) {
      console.error('Error fetching game info:', error);
    }
  };

  useEffect(() => {
    fetchGameInfo(); // Call the function to fetch data when the component mounts
  }, []); // Empty array means this effect runs only once on mount


  useEffect(() => {
    if (countdown === 1) {
      // Call the fetch function and re-render the component
      fetchGameInfo();
      setKey((prevKey) => prevKey + 1);

    
      return () => {
      
      };
    }
  }, [countdown]); // Dependency array ensures this effect runs when countdown changes


  return (
<>
  
<div key={key}> 
<div data-v-353a60df="" data-v-b67ac651="" class="GameRecord__C" apifun="e=>r(c.WinGoGetMyEmerdList,e).then(s=>s.data)" listapi="e=>r(c.WinGoGetNoaverageEmerdList,e).then(s=>s.data)" emerdapi="e=>r(c.WinGoGetEmerdList,e).then(s=>s.data)" gopathname="AllLotteryGames-BettingRecordWin">
               <div data-v-353a60df="" class="GameRecord__C-head">
                  <div data-v-353a60df="" class="van-row">
                     <div data-v-353a60df="" class="van-col van-col--8">Period</div>
                     <div data-v-353a60df="" class="van-col van-col--5">Number</div>
                     <div data-v-353a60df="" class="van-col van-col--5">Big Small</div>
                     <div data-v-353a60df="" class="van-col van-col--6">Color</div>
                  </div>
               </div>
               <div data-v-353a60df="" class="GameRecord__C-body">
               {gameData.map((item, index) => (
                  <div data-v-353a60df="" class="van-row">
                     <div data-v-353a60df="" class="van-col van-col--8">{item.issueNumber}</div>
                     <div data-v-353a60df="" class="van-col van-col--5 numcenter">
                        <div data-v-353a60df="" class="GameRecord__C-body-num greenColor">{item.number}</div>
                     </div>
                     <div data-v-353a60df="" class="van-col van-col--5"><span data-v-353a60df="">{item.number < 5 ? 'Small' : 'Big'}</span></div>
                     <div data-v-353a60df="" class="van-col van-col--6">
                        <div data-v-353a60df="" class="GameRecord__C-origin">
                           {item.colour === "red++violet" ? (
                              <>
                                <div data-v-353a60df="" className="GameRecord__C-origin-I red"></div>
                                <div data-v-353a60df="" className="GameRecord__C-origin-I violet"></div>
                              </>
                            ) : item.colour === "green++violet" ? (
                              <>
                                <div data-v-353a60df="" className="GameRecord__C-origin-I green"></div>
                                <div data-v-353a60df="" className="GameRecord__C-origin-I violet"></div>
                              </>
                            ) : (
                              <div data-v-353a60df="" className={`GameRecord__C-origin-I ${item.colour}`}></div>
                            )}
                       
                        </div>
                     </div>
                  </div>
                ))}
                

               </div>
            </div>
  </div>
  </>
  );
};

export default History;
