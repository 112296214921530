import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Slots = () => {



    return (
  
      <>
  
              
  <div data-v-54ceec2b="" data-v-f5b7f7ac="" class="home_container">

   <div data-v-54ceec2b="" class="title" style={{backgroundImage: "url(https://9987up.co/assets/png/slot-e9664285.png)"}}>Slots Coming Soon</div>
   
   <div data-v-c88a8cc9="" data-v-54ceec2b="" class="other_game"><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033931hy14.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033931hy14.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033941fj7o.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033941fj7o.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215034022ak6f.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215034022ak6f.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033835sgy6.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033835sgy6.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033755o8ni.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033755o8ni.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_202312150337149y13.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_202312150337149y13.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215035143g51j.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215035143g51j.png"/></div>
   
</div> 
</>


);
};

export default Slots;
