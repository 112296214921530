import React, { useState } from 'react';
import PopupContent from './popup'; // Your popup component
import './zoom.css';

import History from './history.js';
import MyHistory from './mybethistory.js'




const Betting = ({countdown }) => {
  // State to manage whether the popup is visible
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [popupContentType, setPopupContentType] = useState('');
  const [zoomedButton, setZoomedButton] = useState(null);
  const [activeTab, setActiveTab] = useState('gameHistory'); // Added here for consistent state management

 
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
 
  const togglePopup = (contentType) => {
    setPopupContentType(contentType);
    setPopupVisibility(true);
  };

  const applyRandomZoomEffect = () => {
    const options = [
      ...Array.from({ length: 10 }, (_, num) => `Number ${num}`), // 0-9 numbers
    ];
  
    let activeZoomInterval;
    let animationCount = 0;
  
    const startZoomEffect = () => {
      activeZoomInterval = setInterval(() => {
        // Randomly select an item from the options array
        const randomIndex = Math.floor(Math.random() * options.length);
        const randomChoice = options[randomIndex];
  
        // Clear existing zoom effects
        document.querySelectorAll('.zoom').forEach((element) => {
          element.classList.remove('zoom');
        });
  
        // Ensure the correct ID format for number elements
        let elementId;
        if (randomChoice.startsWith('Number')) {
          const numberValue = randomChoice.split(' ')[1];
          elementId = numberValue; // ID for numbers (0-9)
        } else {
          elementId = randomChoice.toLowerCase().replace(' ', '');
        }
  
        const element = document.getElementById(elementId);
        if (element) {
          element.classList.add('zoom'); // Apply the zoom effect
        }
  
        animationCount++;
  
        if (animationCount >= 20) { // Stop after at least 20 animations
          clearInterval(activeZoomInterval);
          togglePopup(randomChoice); // Open popup with the final choice
        }
      }, 100); // 100 ms interval for quick animation changes
    };
  
    startZoomEffect(); // Start the zoom effect
  };
  
  

  return (
    <>
    <div data-v-9e8c5029="" data-v-b67ac651="" className="Betting__C" voicetype="1" typeid="1">
        {countdown <= 5 && (
        <div data-v-9e8c5029="" class="Betting__C-mark" style={{display: "block", padding:"1.2rem"}}><div data-v-9e8c5029="">0</div><div data-v-9e8c5029="">{countdown}</div></div>

 
      )}
      <div data-v-9e8c5029="" className="Betting__C-head">
     
      
        <div
          data-v-9e8c5029=""
          id="green"
          className="Betting__C-head-g"
          onClick={() => togglePopup('Green')}
        >
          Green
        </div>
        <div
          data-v-9e8c5029=""
          id="violet"
          className="Betting__C-head-p"
          onClick={() => togglePopup('Violet')}
        >
          Violet
        </div>
        <div
          data-v-9e8c5029=""
          id="red"
          className="Betting__C-head-r"
          onClick={() => togglePopup('Red')}
        >
          Red
        </div>
      </div>
      <div data-v-9e8c5029="" className="Betting__C-numC">
        {[...Array(10).keys()].map((num) => (
          <div
            key={num}
            data-v-9e8c5029=""
            id={num.toString()}
            className={`Betting__C-numC-item${num} main20per`}
            onClick={() => togglePopup(`Number ${num}`)}
          >
          </div>
        ))}
      </div>
      
   {/* Multipliers section */}
   <div data-v-9e8c5029="" className="Betting__C-multiple">
        <div
          data-v-9e8c5029=""
          className="Betting__C-multiple-l"
          onClick={applyRandomZoomEffect} // Call randomToggle when Random is clicked
        >
          Random
        </div>
        {[1, 5, 10, 20, 50, 100].map((multiplier) => (
          <div
            key={multiplier}
            data-v-9e8c5029=""
            className={`Betting__C-multiple-r ${multiplier === 1 ? 'active' : ''}`}
            onClick={() => togglePopup(`X${multiplier}`)}
          >
            X{multiplier}
          </div>
        ))}
      </div>
      <div data-v-9e8c5029="" className="Betting__C-foot">
        <div
          data-v-9e8c5029=""
          id="big"
          className="Betting__C-foot-b"
          onClick={() => togglePopup('Big')}
        >
          Big
        </div>
        <div
          data-v-9e8c5029=""
          id="small"
          className="Betting__C-foot-s"
          onClick={() => togglePopup('Small')}
        >
          Small
        </div>
      </div>
 

      {isPopupVisible && (
        <PopupContent 
       
        type={popupContentType} onClose={() => setPopupVisibility(false)} />
      )}
    </div>




<div data-v-d4ef8fb1="" data-v-b67ac651="" class="RecordNav__C">
<div data-v-d4ef8fb1=""  className={activeTab === 'gameHistory' ? 'active' : ''}
onClick={() => handleTabClick('gameHistory')}>Game history</div>
<div data-v-d4ef8fb1="" className={activeTab === 'chart' ? 'active' : ''}
onClick={() => handleTabClick('chart')}>Chart</div>
<div data-v-d4ef8fb1=""  className={activeTab === 'MyHistory' ? 'active' : ''}
onClick={() => handleTabClick('MyHistory')}>My history</div>
</div>



<div>
{activeTab === 'gameHistory' && <History countdown={countdown} />}
{activeTab === 'MyHistory' && <MyHistory  />}
</div>





</>











  );
};

export default Betting;
