import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Fishing = () => {



    return (
  
      <>
  
  <div data-v-54ceec2b="" data-v-f5b7f7ac="" class="home_container">
   <div data-v-54ceec2b="" class="title" style={{backgroundImage: "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMPSURBVHgB7ZjNTxNREMBntksw0WBVajQx4XE3oVy9AP4Dtol38eLNUA6UeFHqxVAP1L8AvJuIfwE14U4x3lmMiR+QQIgQDd0d521B9uNtd/tBU8P7Je1+ZGZ25r1582YXQKPRaDQajebigq0I77wWkwDOBBBmWTFLAMI1grDP5zU++2CasHpt1rKgBfaWRFoeWW+/iYyw67RRd7B0a96qQCsBNBynF0AwmUQeDVpJpYxS0kB2yyNb8pgawCmVzo9FkUsZtEwEaXbYGi5uj/57VjPDcmRsmxUdyEE7IC1k5r6U4p5RP6a9E2csB7A0MABVGUjUwGWK2xgbwInhNT7NQicgVE0T81Hp8e2VEGaKtqAFvAEYUUK27SxBp85LePTsY9qQOQxdQM6S91oZwM+ymCYHp6FLyMXOs/n+dLF6uf2Mc56LQGJbmCAAQ+ZdgMG7D+HG009w/cm6e94G2ZNZDYGEbyEhFJANBSAXzml59HL5XgHw0hAYV+/AlfvPoR3krH5fFIXg/WMbKklnwbah6r0OBYAUUXEGh84c+XMA7ZJCWuIU9a0tmUb1Oo5TIL9DvgGuuCnnIRQAIY2plI/WXoJz8NV1/nC9Ap2AQMvB9SAdMwycjdKRwfFMhUqyGTJOIEhh4Pfnd+6vS2RlieYg8t6Ni589RpEqmA+Ovns3eGOnPBJt43xY5WnfBIMmlDu9bFMIZ28WrRWVsqlSYENp6B05HvocqIdNOj/FzteilH1rQNb/HjvfFG4rPjZzXuILAJEeQR9hIMRWC38KJew2e4EsmcNzVjVOzghoJd7Sz5OokqnCH4CDb6AvUJdMFb4AMvPWAm9kifuSruO+2eHjuIXrV1GwWxYVApqBHtJoIzDfivMSZTc6XLQKciTiepNuwQt29fAIx1t1vqEbA3enBSKaQUWH2jGIVf4vZRJUm0gTSQXlJsfCD3jXnOxos3MrHcqR7sjxM3Nt0HjZ5s8q3HzxJxWBRILflNK+wNhRbs72CdFiOYvlNvlu7dch1EYXrL4o1xqNRqPRaDT/O38BaH9AZyOw2AQAAAAASUVORK5CYII=&quot;)"}}>Fishing</div>
   
   <div data-v-80dcc128="" data-v-54ceec2b="" class="fish_game">
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/CQ9/AB3.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/CQ9/AT01.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/CQ9/AT05.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/CQ9/GO02.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7001.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7002.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7003.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7004.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7005.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7006.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/7007.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/JDB/67001.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
      <div data-v-80dcc128="" class="item"><img data-v-80dcc128="" src="https://ossimg.9987cw.cc/TC/gamelogo/V8Card/510.png" alt="" data-img="https://9987up.co/assets/png/avatar-5a79e664.png"/></div>
   </div>
   
   <div data-v-54ceec2b="" class="all_btn">View All</div>
</div>
</>


);
};

export default Fishing;
