import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom'; // Use this for React Router v6 and later

import axios from 'axios';
import PopupContent from './gameid';
import Betting from './betting';

const GameItem = () => {
  const [activeGame, setActiveGame] = useState('Win Go1Min');
  const [countdown, setCountdown] = useState(0);
  const [count, setCount] = useState(0);
  const [key, setKey] = useState(0);
  const navigate = useNavigate(); // Call `useNavigate` within the component

  const [issueNumber, setIssueNumber] = useState('');
  const [fetchedIssueNumber, setFetchedIssueNumber] = useState(null); // Initial state set to null
  const reloadComponent = () => {
    // Incrementing the key will cause the component to re-render
    setKey((prevKey) => prevKey + 1);
  };
  const gameTypeMap = {
    'Win Go1Min': 1,
    'Win Go3Min': 2,
    'Win Go5Min': 3,
    'Win Go10Min': 4,
  };

  const fetchGameInfo = async (game) => {
    const typeId = gameTypeMap[game];
    try {
      const response = await axios.post('https://vglottery.com/web_api/GetGameIssue', { typeId });


      if (response.data.code === 0) {
        const { endTime, serviceTime } = response.data.data;
        const start = new Date(serviceTime);
        const end = new Date(endTime);
        const fetchedIssueNumber = response.data.data.issueNumber;

      
        setIssueNumber(fetchedIssueNumber); // Store the issue number in state

           if (isNaN(start) || isNaN(end)) {
          console.error('Invalid time data.');
          return;
        }


        const totalSeconds = Math.floor((end - start) / 1000);
    
        setCountdown(totalSeconds);

    
       
      } else {
        console.error('Failed to fetch game data.');
      }
    } catch (error) {
      console.error('Error fetching game info:', error);
    }
  };


  useEffect(() => {
    if (countdown === 0) {
        fetchGameInfo(activeGame); // Recall function when countdown hits zero
        
    }
}, [countdown]); // Dependency is countdown



  useEffect(() => {
    fetchGameInfo(activeGame);
   
    const intervalId = setInterval(() => {
    
          
      setCountdown((prevCountdown) => Math.max(prevCountdown - 1, 0)); // Ensure countdown doesn't go negative
    }, 1000);
    return () => clearInterval(intervalId); // Cleanup when component is unmounted
  }, [activeGame]);

 
  const defaultGame = 'Win Go1Min'; // Default game name
  const defaultGameId = gameTypeMap[defaultGame]; // Corresponding ID for the default game

  useEffect(() => {
    // Navigate to the default game ID when the component mounts
    navigate(`?wingo=${defaultGameId}`);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleGameClick = (game) => {
    const gameId = gameTypeMap[game]; // Get the mapped ID
    setActiveGame(game); // Set the active game

    navigate(`?wingo=${gameId}`); // Navigate to the specific game ID
  };

  
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

 


  return (



    <>









    <div key={key}> 
      <div  className="GameList__C" data-v-0924c821="" data-v-b67ac651=""  >     

        <div
          className={`GameList__C-item ${activeGame === 'Win Go1Min' ? 'active' : ''}`}
          data-v-0924c821=""
          onClick={() => handleGameClick('Win Go1Min')}
        >
          <div data-v-0924c821="">Win Go1Min</div>
        </div>
        <div
          className={`GameList__C-item ${activeGame === 'Win Go3Min' ? 'active' : ''}`}
          data-v-0924c821=""
          onClick={() => handleGameClick('Win Go3Min')}
        >
          <div data-v-0924c821="">Win Go3Min</div>
        </div>
        <div
          className={`GameList__C-item ${activeGame === 'Win Go5Min' ? 'active' : ''}`}
          data-v-0924c821=""
          onClick={() => handleGameClick('Win Go5Min')}
        >
          <div data-v-0924c821="">Win Go5Min</div>
        </div>
        <div
          className={`GameList__C-item ${activeGame === 'Win Go10Min' ? 'active' : ''}`}
          data-v-0924c821=""
          onClick={() => handleGameClick('Win Go10Min')}
        >
          <div data-v-0924c821="">Win Go10Min</div>
        </div>
      </div>



      <div className="TimeLeft__C" data-v-5126340a="">
        <div className="TimeLeft__C-rule" data-v-5126340a="">How to play</div>
        <div className="TimeLeft__C-name" data-v-5126340a="">{activeGame}</div>

        <div className="TimeLeft__C-num" data-v-5126340a="">
          
          <div className="n7" data-v-5126340a=""></div>
          
          <div className="n9" data-v-5126340a=""></div>
          <div className="n3" data-v-5126340a=""></div>
          <div class="n0" data-v-5126340a=""></div>
          <div class="n4" data-v-5126340a=""></div>
        </div>
        <div data-v-5126340a="" class="TimeLeft__C-id"> {issueNumber}</div> {/* Using the correct state variable */}

  
        <div className="TimeLeft__C-text" data-v-5126340a="">Time remaining</div>
        <div className="TimeLeft__C-time" data-v-5126340a="">
          <div data-v-5126340a="">{minutes}</div>
          <div data-v-5126340a="">:</div>
          <div data-v-5126340a="">{seconds}</div>
        </div>
      </div>
    </div>


    <Betting countdown={countdown} />


 
</>





);
};

export default GameItem;
