import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MyComponent = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate an async operation
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoaded(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      navigate('/en');
    }
  }, [isLoaded, navigate]);

  return (
    <div>
      Loading...
    </div>
  );
};

export default MyComponent;
