import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importing Routes
import WinGo from './com/wingo';
import Login from './com/login';
import Home from './com/home'; // Fix capitalization
import Navbar from './com/navbar';
import popupContent from './com/popup';
import GameItemid from './com/gameid';
import Account from './com/account';






const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/wingo" element={
            <>
            <Navbar/>
          <WinGo />
          <popupContent/>
          </>
          } />
          <Route path="/login" element={<Login />} />
          
          {/* Corrected Route with multiple components */}
          <Route path="/" element={
            <>
              <Home />
            </>
          } />

<Route path="/account" element={
            <>
              <Account />
            </>
          } />


<Route path="/id" element={
            <>
              <GameItemid />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
