import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Casino = () => {



    return (
  
      <>
  
              
  <div data-v-54ceec2b="" data-v-f5b7f7ac="" class="home_container">
   <div data-v-54ceec2b="" class="title" style={{backgroundImage: "url(https://9987up.co/assets/png/video-f7c33287.png)"}}>Casino</div>
   
   <div data-v-c88a8cc9="" data-v-54ceec2b="" class="other_game"><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215053732l6fa.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215053732l6fa.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_202312150538287uu6.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_202312150538287uu6.png"/><img data-v-c88a8cc9="" alt="" class="other_img" data-origin="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033726kjpd.png" src="https://ossimg.9987cw.cc/TC/vendorlogo/vendorlogo_20231215033726kjpd.png"/></div>
   
</div>
</>


);
};

export default Casino;
