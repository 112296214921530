import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MyHistory = () => {
  const [activeGame, setActiveGame] = useState('Win Go1Min');
  const [issueNumber, setIssueNumber] = useState('');
  const [list, setIslist] = useState('');
  const [gameData, setGameData] = useState([]);


  const fetchGameInfo = async () => {
    try {
      const response = await axios.post(
        'https://vglottery.com/web_api/GetNoaverageEmerdList',
        {
          "pageSize": 10,
          "pageNo": 1,
          "typeId": 1
        }
      );
      if (response && response.data && response.data.data && response.data.data.list) {
        setGameData(response.data.data.list);
      } else {
        console.error('Unexpected response code:', response.data.list);
      }
    } catch (error) {
      console.error('Error fetching game info:', error);
    }
  };

  useEffect(() => {
    fetchGameInfo(); // Call the function to fetch data when the component mounts
  }, []); // Empty array means this effect runs only once on mount

  return (
<>
  
<div data-v-90356830="" data-v-b67ac651="" class="MyGameRecord__C" listapi="e=>r(c.WinGoGetNoaverageEmerdList,e).then(s=>s.data)" emerdapi="e=>r(c.WinGoGetEmerdList,e).then(s=>s.data)">
   <div data-v-90356830="" class="MyGameRecord__C-head">
      <div data-v-90356830="" class="MyGameRecord__C-head-moreB">Detail</div>
   </div>
   <div data-v-90356830="" class="MyGameRecord__C-body">
      <div data-v-373b3197="" data-v-90356830="" class="MyGameRecordList__C">
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240427010632</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-27 10:31:41</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹196.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240427010631</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-27 10:30:37</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹98.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240427010630</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-27 10:29:34</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹98.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240427010629</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-27 10:28:37</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹98.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-big">big</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240427010628</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-27 10:27:35</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹49.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-big">big</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240427010627</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-27 10:26:40</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹39.20</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240424010559</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-24 09:18:27</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹98.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240424010558</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-24 09:17:44</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹39.20</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-small">small</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240424010556</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-24 09:15:43</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹19.60</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-red"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240422011096</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-22 18:15:30</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹19.60</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-green"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240422011094</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-22 18:13:34</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹19.60</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-green"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240422011093</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-22 18:12:10</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹39.20</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-big">big</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240422011057</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-22 17:36:07</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹39.20</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-green"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240422011056</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-22 17:35:41</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹19.60</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-big">big</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240421010755</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-21 12:34:26</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹49.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-red"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240421010754</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-21 12:33:48</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹49.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-green"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240421010708</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-21 11:47:27</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹39.20</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-green"></div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240421010703</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-21 11:42:17</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r">
               <div data-v-373b3197="" class="">Failed</div>
               <span data-v-373b3197="">-₹49.00</span>
            </div>
         </div>
         
         <div data-v-373b3197="" class="MyGameRecordList__C-item">
            <div data-v-373b3197="" class="MyGameRecordList__C-item-l MyGameRecordList__C-item-l-big">big</div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-m">
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-top">20240421010674</div>
               <div data-v-373b3197="" class="MyGameRecordList__C-item-m-bottom">2024-04-21 11:13:43</div>
            </div>
            <div data-v-373b3197="" class="MyGameRecordList__C-item-r success">
               <div data-v-373b3197="" class="success">Succeed</div>
               <span data-v-373b3197="">+₹98.00</span>
            </div>
         </div>
         
      </div>
   </div>
   <div data-v-90356830="" class="MyGameRecord__C-foot">
      <div data-v-90356830="" class="MyGameRecord__C-foot-previous disabled">
         <i data-v-90356830="" class="van-badge__wrapper van-icon van-icon-arrow-left MyGameRecord__C-icon" style={{fontSize: "20px;"}}>
            
         </i>
      </div>
      <div data-v-90356830="" class="MyGameRecord__C-foot-page">1/1</div>
      <div data-v-90356830="" class="MyGameRecord__C-foot-next disabled">
         <i data-v-90356830="" class="van-badge__wrapper van-icon van-icon-arrow MyGameRecord__C-icon" style={{fontSize: "20px;"}}>
            
         </i>
      </div>
   </div>
</div>
  
  </>
  );
};

export default MyHistory;
