import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
   const [userId, setEmail] = useState("");
  const [password, setPassword] = useState();
  const [authenticated, setAuthenticated] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState(localStorage.getItem('user_access_token') || null);
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
 
  const navigate = useNavigate();








  
   useEffect(() => {
   if (token) {
      navigate('/#');
   }
   }, [token, navigate]);

  const axiosInstance = axios.create({
    baseURL: "https://vingrow.club/vingrow_app/",
    timeout: 3000,
    headers: { Authorization: `bearer ${token}` },
  });

  const authenticateUser = async () => {
    if (!userId || !password) {
      alert("empty");
      return;
    }

    setAuthenticated(true);

    await axios
      .post('https://vingrow.club/vingrow_app/user_auth', {
        userId: userId,
        password: password,
      })
      .then((response) => {
        if (response.data.authenticated) {
          setToken(response.data.token.accessToken);
          localStorage.setItem('user_access_token', response.data.token.accessToken);
          localStorage.setItem('userId', response.data.uid);
          setErrorMessage(''); // Clear error message if login is successful
        } else {
          setErrorMessage('Login failed. Please try again.');
        }
      })
      .catch((error) => {
        setErrorMessage('Error: 101 User does not exist');
        console.log(error);
      });
  };

  useEffect(() => { 
    setTimeout(() => {
      if (token) {
        async function validateToken() {
          try {
            const response = await axiosInstance.post("/validate");
            console.log(response);
            if (response) {
              
            }
          } catch (error) {
            console.log(error);
          }
        }

        validateToken();
      }
    }, 1000);
  }, [token, axiosInstance]);


  
  useEffect(() => {
   // Function to update the root font size based on viewport width
   function updateFontSize() {
     const vw = window.innerWidth; // Get viewport width
     let fontSize;

     // If the viewport width is less than 500px, adjust the font size
     if (vw < 500) {
       fontSize = vw / 8; // Calculate font size as a fraction of viewport width
     } else {
       fontSize = 50; // Default font size for larger screens
     }

     // Set the font size for the root element
     document.documentElement.style.fontSize = fontSize + 'px';
   }

   // Update font size when the component is mounted
   updateFontSize();

   // Add event listeners to update font size on window resize
   window.addEventListener('resize', updateFontSize);

   // Cleanup: remove the event listener on component unmount to prevent memory leaks
   return () => {
     window.removeEventListener('resize', updateFontSize);
   };
 }, []); // Empty dependency array to run the effect only once after mount

  useEffect(() => {
   const handleResize = () => {
     const screenWidth = window.innerWidth;

     if (screenWidth < 500) {
       // Set maxWidth to the current screen width if it's under 500px
       setMaxWidth(screenWidth);
     } else {
       // Reset to default (500px) if the screen width is 500px or more
       setMaxWidth(500);
     }
   };

   // Add event listener for window resize
   window.addEventListener('resize', handleResize);

   // Run the effect once initially to set the initial max width
   handleResize();

   // Cleanup function to remove the event listener when the component is unmounted
   return () => {
     window.removeEventListener('resize', handleResize);
   };
 }, []); // Empty dependency array ensures effect is only set up once on mount

 useEffect(() => {
   let timer;
   if (errorMessage) {
     timer = setTimeout(() => {
       setErrorMessage(''); // Clear the error message after 1 second
     }, 1000); // 1000 milliseconds is 1 second
   }

   return () => clearTimeout(timer); // Clean up the timer when component unmounts or error message changes
 }, [errorMessage]); // Dependency on errorMessage ensures the effect runs when it changes


   // State to track whether the password is visible

   // State for the password value

   // Function to toggle the visibility of the password
   const togglePasswordVisibility = () => {
     setIsPasswordVisible((prev) => !prev); // Toggle the state
   }

  return (
    <>



<link rel="stylesheet" href="https://9987up.co/assets/css/modules-8b01ca49.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/page-activity-edd5885d.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/page-home-5716c41f.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/index-053c656b.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/reset-7514927e.css"/>
   <link rel="stylesheet" href="https://9987up.co/assets/css/page-login-3c8cfc52.css"/>
   <link rel="stylesheet" href="https://9987up.co/assets/css/page-main-b744f3fd.css"/>


<div id="app" data-v-app="">
   <div data-v-94f2e2ba="" class="ar-loading-view" style={{display: "none"}} >
      <div data-v-94f2e2ba="" class="loading-wrapper">
         <div data-v-94f2e2ba="" class="loading-animat">
           
         </div>
         <div data-v-94f2e2ba="" class="com__box" style={{display: "none"}}>
            <div class="loading" data-v-94f2e2ba="">
               <div class="shape shape-1" data-v-94f2e2ba=""></div>
               <div class="shape shape-2" data-v-94f2e2ba=""></div>
               <div class="shape shape-3" data-v-94f2e2ba=""></div>
               <div class="shape shape-4" data-v-94f2e2ba=""></div>
            </div>
         </div>
      </div>
      <div data-v-94f2e2ba="" class="skeleton-wrapper" style={{display: "none"}}>
         <div data-v-94f2e2ba="" class="van-skeleton van-skeleton--animate">
            
            <div class="van-skeleton__content">
               
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "60%"}}></div>
            </div>
         </div>
         <div data-v-94f2e2ba="" class="van-skeleton van-skeleton--animate">
            <div class="van-skeleton-avatar van-skeleton-avatar--round"></div>
            <div class="van-skeleton__content">
               <h3 class="van-skeleton-title"></h3>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "60%"}}></div>
            </div>
         </div>
         <div data-v-94f2e2ba="" class="van-skeleton van-skeleton--animate">
            
            <div class="van-skeleton__content">
               <h3 class="van-skeleton-title"></h3>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "100"}}></div>
               <div class="van-skeleton-paragraph" style={{width: "60%"}}></div>
            </div>
         </div>
      </div>
   </div>
   
   <div data-v-b32c781c=""  style={{ maxWidth: `${maxWidth}px` }}  class="login__container" >
      <div data-v-3949aac6="" data-v-b32c781c="" class="navbar">
         <div data-v-3949aac6="" class="navbar-fixed" style={{background: "linear-gradient(90deg, rgb(255, 152, 1) 0%, rgb(230, 116, 1) 100%)"}}>
            <div data-v-3949aac6="" class="navbar__content">
               <div data-v-3949aac6="" class="navbar__content-left">
               <Link to="/" className="backIcon" data-v-3949aac6="">
               <div data-v-3949aac6="" class="backIcon">
               
               </div>
               </Link>
               </div>
               <div data-v-3949aac6="" class="navbar__content-center">
                  <div data-v-3949aac6="" class="headLogo" style={{backgroundImage: "url(https://vglottery.com/assets/logo-white.png"}}></div>
                  <div data-v-3949aac6="" class="navbar__content-title"></div>
               </div>
               <div data-v-3949aac6="" class="navbar__content-right">
                  <div data-v-4eb25d27="" data-v-b32c781c="">
                     <div data-v-4eb25d27="" class="right">
                        <div data-v-4eb25d27="" class="img"><img data-v-4eb25d27="" class="" data-origin="https://9987up.co/assets/png/en-4c6eba8e.png" src="https://9987up.co/assets/png/en-4c6eba8e.png"/></div>
                        EN
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div data-v-b32c781c="" class="login__container-heading">
         <h1 data-v-b32c781c="" class="login__container-heading__title">Log in</h1>
         <div data-v-b32c781c="" class="login__container-heading__subTitle">
            <div data-v-b32c781c="">Please log in with your email</div>
            <div data-v-b32c781c="">If you forget your password, please contact customer service</div>
         </div>
      </div>
      <div data-v-b32c781c="" class="login_container-tab">
         
         <div data-v-b32c781c="" class="tab active">
            <div data-v-b32c781c="" class="emialbg emailactive"></div>
            <div data-v-b32c781c="" class="font30 emailfont30active">User Login</div>
         </div>
      </div>
      <div data-v-b32c781c="" class="login__container-form">
         <div data-v-b32c781c="" class="tab-content activecontent">
            <div data-v-06fff414="" data-v-b32c781c="" class="signIn__container">
               <div data-v-9ea79214="" data-v-06fff414="" class="emailcontainer">
                  <div data-v-9ea79214="" class="emailinput__container">
                     <div data-v-9ea79214="" class="emailinput__container-label"><img data-v-9ea79214="" src="https://9987up.co/assets/png/emailnumber-f8530456.png" class="emailinput__container-label__icon"/><span data-v-9ea79214="">User Id</span></div>
                     <div data-v-9ea79214="" class="emailinput__container-input"><input data-v-9ea79214="" type="text" onChange={(event) => setEmail(event.target.value)} value={userId} name="userEmail" maxlength="250" placeholder="please input your user id"/></div>
                  </div>
               </div>
               <div data-v-a4bb5eb4="" data-v-06fff414="" class="passwordInput__container">
                  <div data-v-a4bb5eb4="" class="passwordInput__container-label"><img data-v-a4bb5eb4="" class="passwordInput__container-label__icon" data-origin="https://9987up.co/assets/png/password-c9fb65d7.png" src="https://9987up.co/assets/png/password-c9fb65d7.png"/><span data-v-a4bb5eb4="">Password</span></div>
                  <div data-v-a4bb5eb4="" class="passwordInput__container-input"><input data-v-a4bb5eb4=""  type={isPasswordVisible ? 'text' : 'password'}  value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Password" maxlength="32" autocomplete="new-password"/><img onClick={togglePasswordVisibility} data-v-a4bb5eb4="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAP1BMVEUAAADExMTExMTDw8PExMTFxcXFxcXExMS/v7/FxcXFxcXExMTExMTDw8PExMTFxcXFxcXExMTPz8+/v7/ExMTkagEbAAAAFHRSTlMAIO/ff29fvxDfMD/PoI+fr1AQMBwdwB4AAAFQSURBVDjL5ZJbkoQgDEXDI4iAqN3sf61DIEFHqmvmv/OhEE5ycwvgm0Il+z9uLQU/HJ0OrTvD4IqdoZdbdOHQh+sczphlSmKlvZu4uPZG2bkqfnDRzFlKL/FKuM9cig+/OoLCmcs8Au7ZqUDcG4IW00OGE2Fh040D/G1baeFO8mDo07gmpdUAa5djVGQPgej3OFtuwqZX7U3o1C0RmjevL+/rWLZqVzqnyV0bc70atgVJInPUaR/ip5TkATrmIBYBd3Gq6pGSVWpc18mPY9jYGKk0jgeP0nCjPxtDTgpnZXDkjGy0kgv3AK+4FG6opMmQXJVwob8wK0/jgCu8qWS+c/oU7hJumUqyrl2MOdDLzd85maVs/p6iQScOMnG1HcYXANlBwsriH9zWua2NZ4zmR8l+Jy6Ycotk/czJfQU8kqmRdgwwhU9y7X+F3yx8VfwAZv4b1F/KTEQAAAAASUVORK5CYII=" class="eye"/></div>
               </div>
              
               <div data-v-06fff414="" class="signIn__container-button"><button data-v-06fff414="" onClick={() => authenticateUser()} class="active">Log in</button><button data-v-06fff414="" class="register">Register</button></div>
               <div data-v-06fff414="" class="signIn_footer">
                  
                  <div data-v-06fff414="" class="customcon">
                     <div data-v-06fff414="" class="custombg"></div>
                     <div data-v-06fff414="" class="font24">Customer Service</div>
                  </div>
               </div>
               <div data-v-9f348ae7="" data-v-06fff414="" class="dialog inactive">
                  <div data-v-9f348ae7="" class="dialog__container" role="dialog" tabindex="0">
                     <div data-v-9f348ae7="" class="dialog__container-img"><img data-v-9f348ae7="" alt="" class="" data-origin="https://9987up.co/assets/png/orderCancelWarn-a1dd1459.png" src="https://9987up.co/assets/png/orderCancelWarn-a1dd1459.png"/></div>
                     <div data-v-9f348ae7="" class="dialog__container-title">
                        <h1 data-v-9f348ae7="">Account has been locked</h1>
                     </div>
                     <div data-v-9f348ae7="" class="dialog__container-content">
                        <div data-v-06fff414="" class="idlockTip">You Have Entered Wrong Password More Then 10 Times <br data-v-06fff414=""/>Please Use Forgot Password To Change New Password For Unlock And Log In</div>
                     </div>
                     <div data-v-9f348ae7="" class="dialog__container-footer"><button data-v-06fff414="" class="dialogBtn">Cancel</button><button data-v-06fff414="" class="dialogBtn"><img data-v-06fff414="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALHSURBVHgB7ZntcdswDIYhL1CNwA2iDapO0GyQjqANnA3qTqBskG4gdwK7E1CdoOkEb8kjfZVVC4Aoys4PP3c4x5EJ4oMCQYnozp1FFJQJALX7+OikilJGOdFHOTr54WRfFMUb3RJvtJOvTn4jjTY6fnXDKycd8mGv5ghCxDX4rOwQsvSoHNM6MbQGXrGTg8II66RxUg7GdtBjszsRjbfCxD7iz0PDB+Pn4nU9UQ6i8dJNap1UzPhUljkBXeQ7XIj6SE+JcC80mLecfOAqSgXymn+lBGJgXqDDSgGammQrKD4kKf7fEQuZNkWxFBVDGUBYXpps1HOUtoKyL5QZhROdVpEU/Xnp1DvgM2GxNAs+uoISQyuBUKmWBQ985flOKwN+KfmyWnKDS/A80sooslAPf78Zja8F/XtaGXdG2AvznG1sG+7iiMkDSMyc71JfIeycCPtLB76S/WSuPXDKd0zqdsy4VrNOEVqJyeUw+B3Xfp+V03EGuJ21Z67VIx1TWfg8+m5o/lxm+GVDeq55flXPNceB98KZcxvu4ohFjVvkl3I+Q9OwDvQ0zQNz7dvg730shZfYDQzoKTxiuQRXyaYrlLCJWGJA6KHEw8fpd2B21FiOp2g45SX442NNV0CwoZIGd8zgVTrR0fxcM2k1ChrwGFoR8C21qhuVlpHuYJFm/BY5gofwfIdjS5mB/PROv3wVWcjqBEJVkuYzc3RqIpLFCafjSWH8M6UAvjs9YZFwYyNkuVXoP1AqcRLNA11A+awf894pWCk4hTCZcR++6hjS41uFY5Q/8X8foo6a9D1V7+STa0t6SiFGyuI2WKTsNwhLZu4D2Nx0SH1kibCNW9wGf080tBSEblFTfXIafvHFSA5HvGKLdVjH8Aln/Kb2ksEZb3SLjG357BfdCP34SfwpzUfQ0Hl5fKN/5dQfI3sKJ7Uj3bnzvvgL2286lgTmf+MAAAAASUVORK5CYII="/> Contact customer service</button></div>
                     
                  </div>
                  <div data-v-9f348ae7="" class="dialog__outside"></div>
               </div>

               {errorMessage && (

               <div data-v-1a514af9="" data-v-06fff414="" class="popups">
               <div role="dialog" tabindex="0" class="van-popup van-popup--center van-toast van-toast--middle van-toast--break-word van-toast--fail" style={{zIndex: "2008", display: "block"}}><i class="van-badge__wrapper van-icon van-icon-fail van-toast__icon"></i><div class="van-toast__text">Error: 101 
 User does not exist</div></div>


               </div>)}

            </div>
         </div>
      </div>
   </div>
   
</div>   


    </>
   



  );
};

export default Login;
