import React, { useState, useEffect } from 'react';
import '../assets/css/wingo/index.css';
const Navbar = () => {
  const [activeGame, setActiveGame] = useState('Win Go1Min');
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);

 const handleGameClick = (gameName) => {
   setActiveGame(gameName);
 };


 useEffect(() => {
   function updateFontSize() {
     let vw = window.innerWidth;
     let fontSize = vw / 10; // Calculate font size as 1/14th of viewport width
     document.documentElement.style.fontSize = fontSize + 'px';
   }

   // Call updateFontSize after the component is mounted
   updateFontSize();

   // Add event listeners to update font size on window resize
   window.addEventListener('resize', updateFontSize);

   // Remove event listener on component unmount to prevent memory leaks
   return () => {
     window.removeEventListener('resize', updateFontSize);
   };
 }, []); // Empty dependency array ensures this effect runs only once after mount

 useEffect(() => {
  const handleResize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 500) {
      // Set maxWidth to the current screen width if it's under 500px
      setMaxWidth(screenWidth);
    } else {
      // Reset to default (500px) if the screen width is 500px or more
      setMaxWidth(500);
    }
  };

  // Add event listener for window resize
  window.addEventListener('resize', handleResize);

  // Run the effect once initially to set the initial max width
  handleResize();

  // Cleanup function to remove the event listener when the component is unmounted
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []); // Empty dependency array ensures effect is only set up once on mount


  return (
<>
    
<link rel="stylesheet" href="https://9987up.co/assets/css/modules-8b01ca49.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/page-activity-edd5885d.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/page-home-5716c41f.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/index-053c656b.css"/>
<link rel="stylesheet" href="https://9987up.co/assets/css/reset-7514927e.css"/>

    <div style={{ maxWidth: `${maxWidth}px` }} className="navbar">
      <div
        className="navbar-fixed"
        style={{
          background: 'linear-gradient(90deg, rgb(255, 153, 1) 0%, rgb(230, 115, 1) 100%)'
          , maxWidth: `${maxWidth}px`
        }}
        
      >
        <div className="navbar__content">
          <div className="navbar__content-left">
            <div className="backIcon"></div> {/* Back icon section */}
          </div>
          <div className="navbar__content-center">
            <div
              className="headLogo"
              style={{
                backgroundImage: 'url(https://vglottery.com/assets/logo-white.png)',
              }}
            ></div>
            <div className="navbar__content-title"></div> {/* Title section */}
          </div>
          <div className="navbar__content-right">
            <div className="WinGo__C-head-more">
              <div></div>
              <div className=""></div> {/* Additional icons or functionality */}
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
};

export default Navbar;
