import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Ltgame = () => {



    return (
  
      <>
  
              
  <div
  style={{ padding: "0 .34667rem" }} // Correct camelCase
  data-v-ce53101a=""
  data-v-54ceec2b=""
  className="lottery_container" // Use className for JSX
>               <div data-v-ce53101a="" class="lotterySlotItem">
               <div
  data-v-54ceec2b=""
  className="title"
  style={{ backgroundImage: "url('https://9987up.co/assets/png/lottery-7b8f3f55.png')" }}
>
  Lottery
</div>
               <Link to="/wingo">
                  <div data-v-ce53101a="" class="lottery">
                     <img data-v-ce53101a="" src="https://9987up.co/assets/png/XOSO_bg-33e377d7.png" alt="" class="lottery_bg"/><img data-v-ce53101a="" class="lottery_logo" data-origin="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_202312150334238m3v.png" src="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_202312150334238m3v.png"/><span data-v-ce53101a="">Win Go</span>
                     <h4 data-v-ce53101a="">
                        <div data-v-ce53101a="">Guess Number</div>
                        <div data-v-ce53101a="">Green/Red/Purple to win</div>
                     </h4>
                  </div>
                  </Link>
                  <div data-v-ce53101a="" class="win_info">
                     <div data-v-ce53101a="" id="1win"></div>
                  </div>
               </div>
               <div data-v-ce53101a="" class="lotterySlotItem"><div data-v-ce53101a="" class="lottery"><img data-v-ce53101a="" src="https://9987up.co/assets/png/K3_bg-7ea7295a.png" alt="" class="lottery_bg"/><img data-v-ce53101a="" class="lottery_logo" data-origin="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033442jmqv.png" src="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033442jmqv.png"/><span data-v-ce53101a="">K3 Lotre</span><h4 data-v-ce53101a=""><div data-v-ce53101a="">Guess Number</div><div data-v-ce53101a="">Big/Small/Odd/Even</div></h4></div><div data-v-ce53101a="" class="win_info">
                  <div data-v-ce53101a="" id="2win"></div></div></div>
               <div data-v-ce53101a="" class="lotterySlotItem"><div data-v-ce53101a="" class="lottery"><img data-v-ce53101a="" src="https://9987up.co/assets/png/5D_bg-75220c52.png" alt="" class="lottery_bg"/><img data-v-ce53101a="" class="lottery_logo" data-origin="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033448bjlv.png" src="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033448bjlv.png"/><span data-v-ce53101a="">5D Lotre</span><h4 data-v-ce53101a=""><div data-v-ce53101a="">Guess Number</div><div data-v-ce53101a="">Big/Small/Odd/Even</div></h4></div><div data-v-ce53101a="" class="win_info"><div data-v-ce53101a="" id="3win"></div></div></div>
               <div data-v-ce53101a="" class="lotterySlotItem"><div data-v-ce53101a="" class="lottery"><img data-v-ce53101a="" src="https://9987up.co/assets/png/Bingo18_bg-7db31dc0.png" alt="" class="lottery_bg"/><img data-v-ce53101a="" class="lottery_logo" data-origin="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033454m1k3.png" src="https://ossimg.9987cw.cc/TC/lotterycategory/lotterycategory_20231215033454m1k3.png"/><span data-v-ce53101a="">Trx Win</span><h4 data-v-ce53101a=""><div data-v-ce53101a="">Guess Number</div><div data-v-ce53101a="">Green/Red/Purple to win</div></h4></div><div data-v-ce53101a="" class="win_info"><div data-v-ce53101a="" id="4win"></div></div></div>
            </div>
           
</>

);
};

export default Ltgame;
