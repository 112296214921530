import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { EventEmitter } from 'events';
import eventEmitter from '../events';

const Wallet = () => {
  const [balance, setBalance] = useState(localStorage.getItem('wallet_balance') || 0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const fetchBalance = async () => {
    setIsRefreshing(true);
    setShowToast(false);

    try {
      const token = localStorage.getItem('user_access_token');
      const userId = localStorage.getItem('userId');
      if (!token) {
        return;
      }

      const response = await axios.post(
        'https://vglottery.com/web_api/GetBalance',
        { userId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.code === 0) {
        const amount = response.data.data.amount;
        setBalance(amount);
        localStorage.setItem('wallet_balance', amount); // Save balance in local storage
      } else {
        console.error('Failed to fetch balance:', response.data.msg);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }

    setIsRefreshing(false);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  useEffect(() => {
    fetchBalance(); // Fetch balance on component mount

    const handleWalletReload = () => {
      fetchBalance();
    };

    eventEmitter.on('walletReload', handleWalletReload);

    return () => {
      eventEmitter.off('walletReload', handleWalletReload);
    };
  }, []);

  
  return (
    <>




       <div data-v-e48466e6="" data-v-b67ac651="" class="Wallet__C">
               <div data-v-e48466e6="" class="Wallet__C-balance">

<div
  
  data-v-e48466e6=""
  className="Wallet__C-balance-l1" // Use className instead of class in React
>
  <div data-v-e48466e6="" onClick={() => fetchBalance()} >
    ₹{balance}{' '}
    {isRefreshing && ( // Conditionally render the "Refresh successfully" message
      <div
        role="dialog"
        tabIndex="0"
        className="van-popup van-popup--center van-toast van-toast--middle van-toast--break-word van-toast--text"
        style={{ top: "25%" }}
      >
        <div className="van-toast__text">Refresh successfully</div>
      </div>
    )}
  </div>
</div>



                  <div data-v-e48466e6="" class="Wallet__C-balance-l2">
                     <div data-v-e48466e6="">Wallet balance (Testing)</div>
                  </div>
                  <div data-v-e48466e6="" class="Wallet__C-balance-l3">
                     <div data-v-e48466e6="">Withdraw</div>
                     <div data-v-e48466e6="">Deposit</div>
                  </div>
               </div>
            </div>


            </>
  );
};

export default Wallet;
